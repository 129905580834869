import React, { useContext, useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Spinner
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  RSelect,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { filterRole } from "./UserData";
import { bulkActionOptions, findUpper } from "../../../utils/Utils";
import { Link } from "react-router-dom";
import { UserContext } from "./UserContext";
import EditModal from "./EditModal";
import DeviceAddModal from "./DeviceAddModal";
import axios from "axios";

var unique_userid = localStorage.getItem('unique_id')
var user_key = localStorage.getItem('user_key')

const filterStatus = [
  { value: "", label: "All Status" }, // Add this line
  { value: "1", label: "Active" },
  { value: "0", label: "Suspended" },
];

const DeviceListRegularPage = () => {
  
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  
  
  const [filteredData, setFilteredData] = useState([]); // state to hold filtered data
  const [statusFilter, setStatusFilter] = useState(""); // state to hold status filter value
  const [minAmount, setMinAmount] = useState(""); // state to hold min amount filter value
  const [maxAmount, setMaxAmount] = useState(""); // state to hold max amount filter value
  const [lastSeenHours, setLastSeenHours] = useState("");
  const [deviceCount, setdeviceCount] = useState(0);

  useEffect(() => {
    // Fetch user data from the API and update the userData state
    const fetchData=async()=>{
      try{
        const response  = await axios.get('https://api.karthi.ind.in/pages/users/read.php',
          {
            headers: {
              'X-User-Key': user_key
            }
          }
        )
        console.log(response.data.data)
        setdeviceCount(response.data.data.length)
        setUserData(response.data.data.map(user => ({
          id: user.id,
          avatarBg: user.avatarBg || "purple", // Set default image URL if not provided
          name: user.name || "",
          user_unique_id : user.unique_id || "-",
          mail : user.mail || "-",
          phone : user.phone || "-",
          role : user.role || "-",
          address : user.address || "-",
          status: user.status || "Active",
          license_date : user.license_date || "-",
          update_date : user.update_date || "-",
          added_date : user.created_date || "-",         
          // Set default values for other properties as needed
        })));
  
        setData(response.data.data.map(user => ({
          id: user.id,
          avatarBg: user.avatarBg || "purple", // Set default image URL if not provided
          name: user.name || "",
          user_unique_id : user.unique_id || "-",
          mail : user.mail || "-",
          phone : user.phone || "-",
          role : user.role || "-",
          address : user.address || "-",
          status: user.status || "Active",
          license_date : user.license_date || "-",
          update_date : user.update_date || "-",
          added_date : user.created_date || "-",  
          // Set default values for other properties as needed
        })));
  
      } catch(e) {
        console.log(e)
      }
      finally {
        setLoading(false)
      }
    }

    fetchData();
  }, []); // Empty dependency array to ensure it only runs once on mount
  
  const { contextData } = useContext(UserContext);
  

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name : "",
    device_mac: "",
    device_model: "",
    status: "Active"
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    email: "",
    balance: 0,
    phone: "",
    status: "",
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = data.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.user_unique_id.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.mail.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.phone.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setUserData([...filteredObject]);
    } else {
      setUserData([...data]);
    }
  }, [onSearchText, setData]);

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };


  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      balance:0,
      phone: "",
      status: "Active",
    });
  };

  const closeModal = () => {
    setModal({ add: false })
    resetForm();
  };

  const closeEditModal = () => {
    setModal({ edit: false })
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = (submitData) => {
    const { device_mac, device_model, name, status } = submitData;
    let submittedData = {
      device_name : name,
      mac: device_mac,
      device_model: device_model,
      user_unique_id: unique_userid
    };
    console.log(submittedData)
    const postData = async () => {
      try {
        const response = await axios.post(
          "https://api.karthi.ind.in/pages/devices/create.php",
          submittedData,
          {
            headers: {
              'X-User-Key': user_key
            }
          }
        );
    
        if (response.status === 200) {
          setModal({ edit: false, add: false });
        }
      } catch (error) {
        console.error('Error posting data:', error);
      }
    };
    postData();
    resetForm();
  };

  // submit function to update a new item
  const onEditSubmit = (submitData) => {
    const { name, email, phone } = submitData;
    let submittedData;
    let newitems = data;
    newitems.forEach((item) => {
      if (item.id === editId) {
        submittedData = {
          id: item.id,
          avatarBg: item.avatarBg,
          name: name,
          image: item.image,
          role: item.role,
          email: email,
          balance: editFormData.balance,
          phone: phone,
          emailStatus: item.emailStatus,
          kycStatus: item.kycStatus,
          lastLogin: item.lastLogin,
          status: editFormData.status,
          country: item.country,
        };
      }
    });
    let index = newitems.findIndex((item) => item.id === editId);
    newitems[index] = submittedData;
    setModal({ edit: false });
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setEditFormData({
          name: item.name,
          email: item.email,
          status: item.status,
          phone: item.phone,
          balance: item.balance,
        });
        setModal({ edit: true }, { add: false });
        setEditedId(id);
      }
    });
  };
  
  // function to change to suspend property for an item
  const suspendUser = (id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].status = "Suspend";
    setData([...newData]);
  };


  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = userData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to handle resetting filters
  const resetFilters = () => {
    setStatusFilter("");
    setUserData(data) // Apply filters again after resetting
  };

  const applyFilters = (statusFilter) => {
    console.log(statusFilter)
    if (statusFilter !== ""){
      const filteredData = data.filter(item => item.status === statusFilter);
      setUserData(filteredData);
    }
    else{
      setUserData(data)
    }
  };
   

  return (
    <React.Fragment>
      <Head title="Device List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                User List
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {deviceCount} users.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Button color="light" outline className="btn-white">
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">
                  </div>
                </div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap" >
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                <div className="dot dot-primary"></div>
                                <Icon name="filter-alt"></Icon>
                              </DropdownToggle>
                              <DropdownMenu
                                end
                                className="filter-wg dropdown-menu-xl"
                                style={{ overflow: "visible" }}
                              >
                                <div className="dropdown-head">
                                  <span className="sub-title dropdown-title">Filter Devices</span>
                                </div>
                                <div className="dropdown-body dropdown-body-rg">
                                  <Row className="g-gs">
                                    <Col size="6">
                                      <div className="form-group">
                                        <label className="overline-title overline-title-alt">Status</label>
                                        <RSelect
                                          options={filterStatus}
                                          placeholder="All Status"
                                          onChange={(selectedOption) => applyFilters(selectedOption.value)}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`} style={{ height: "80px" }}>
                <div className="card-body" >
                  <div className="search-content" >
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by user or email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
        <Block>
          <DataTable className="card-stretch">
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text text-center">Name</span>
                </DataTableRow>
                <DataTableRow size="mb">
                  <span className="sub-text text-center">Mail</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text text-center">Phone</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-center">License date</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text text-center">Status</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools text-end">
                  <UncontrolledDropdown>
                    
                  </UncontrolledDropdown>
                </DataTableRow>
              </DataTableHead>

              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        
                        <DataTableRow className='text-center'>
                          <Link to={`${process.env.PUBLIC_URL}/user-details-regular/${item.user_unique_id}`}>
                            <div className="user-card d-flex-column align-items-center justify-content-center">
                              <div className="user-info">
                                <div>
                                <span className="tb-lead">
                                  {item.name}
                                </span>
                                <span>{item.user_unique_id}</span>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </DataTableRow>
                        <DataTableRow size="mb" className='text-center'>
                          <span className="">
                            {item.mail}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="md" className='text-center'>
                          <span>{item.phone}</span>
                        </DataTableRow>
                        <DataTableRow size="lg" className='text-center'>
                          <span>{item.license_date}</span>
                        </DataTableRow>
                        
                        <DataTableRow size="md" className='text-center'>
                          <span
                            className={`tb-status text-${
                              item.status === "1" ? "success" : item.status === "0" ? "danger" : "warning"
                            }`}
                          >
                            {item.status === "1" ? "Active" : "Inactive"}
                          </span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-center">
                          <ul className="nk-tb-actions gx-1">
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onEditClick(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>
                                    {item.status !== "Suspend" && (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li onClick={() => suspendUser(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#suspend"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="na"></Icon>
                                            <span>Suspend User</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    )}
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            {/* <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={userData.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div> */}
            <div className="card-inner">
              {loading ? (
                // Show Spinner component when loading is true
                <Spinner style={{ display: 'block', margin: 'auto' }}/>
              ) : (
                // Show PaginationComponent if there are items
                currentItems.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={userData.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : (
                  // If there are no items, show "No data found" message
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )
              )}
            </div>
          </DataTable>
        </Block>

        <DeviceAddModal modal={modal.add} formData={formData} setFormData={setFormData} closeModal={closeModal} onSubmit={onFormSubmit} filterStatus={filterStatus} />
        <EditModal modal={modal.edit} formData={editFormData} setFormData={setEditFormData} closeModal={closeEditModal} onSubmit={onEditSubmit} filterStatus={filterStatus} />
        
      </Content>
    </React.Fragment>
  );
};
export default DeviceListRegularPage;
