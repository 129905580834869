import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  Form,
  Spinner,
} from "reactstrap";
import {
  Icon,
  Col,
  Button,
  RSelect,
} from "../../../components/Component";
import { useForm } from "react-hook-form";

const DeviceAddModal = ({ modal, closeModal, onSubmit, formData, setFormData, filterStatus, userData }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reset(formData);
  }, [formData]);

  const { reset, register, handleSubmit, formState: { errors } } = useForm();

  const handleFormSubmit = async (data) => {
    setLoading(true);
    await onSubmit(data);
  };

  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Add User</h5>
          <div className="mt-4">
            <Form className="row gy-4" noValidate onSubmit={handleSubmit(handleFormSubmit)}>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Device name</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register('name', { required: "This field is required" })}
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    placeholder="Enter device name"
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Device Mac</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register('device_mac', { required: "This field is required" })}
                    value={formData.device_mac}
                    onChange={(e) => setFormData({ ...formData, device_mac: e.target.value })}
                    placeholder="Enter device mac"
                  />
                  {errors.device_mac && <span className="invalid">{errors.device_mac.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Device model</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register('device_model', { required: "This field is required" })}
                    value={formData.device_model}
                    onChange={(e) => setFormData({ ...formData, device_model: e.target.value })}
                    placeholder="Enter device model (eg: ES8266)"
                  />
                  {errors.device_model && <span className="invalid">{errors.device_model.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Status</label>
                  <div className="form-control-wrap">
                    <RSelect
                      options={filterStatus}
                          // value={{
                          //   value: formData.status,
                          //   label: formData.status,
                          // }}
                      onChange={(e) => setFormData({ ...formData, status: e.value })}
                    />
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">User</label>
                  <div className="form-control-wrap">
                    <RSelect
                      options={userData}
                      onChange={(e) => setFormData({ ...formData, user_id: e.value })}
                    />
                  </div>
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit" disabled={loading}>
                      {loading ? <Spinner size="sm" /> : "Add Device"}
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeviceAddModal;
