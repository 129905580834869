const userName = localStorage.getItem('userName')
const userEmail = localStorage.getItem('userEmail')
const userRole = localStorage.getItem('userRole')

let menu; // Declare menu outside of the if statements

if (userRole == "6"){
  menu = [
    {
      icon: "home-fill",
      text: "Dashboard",
      link: "/",
    },
    // {
    //   heading: "Pre-built Pages",
    // },
    {
      icon: "cpu",
      text: "Manage Devices",
      active: false,
      subMenu: [
        {
          text: "Device List",
          link: "/device-list-regular",
        }
      ],
    },
    {
      icon: "user-list-fill",
      text: "Manage Users",
      active: false,
      subMenu: [
        {
          text: "User List",
          link: "/user-list-regular",
        }
      ],
    },
    // {
    //   icon: "tranx",
    //   text: "Transaction",
    //   active: false,
    //   subMenu: [
    //     {
    //       text: "Trans List - Basic",
    //       link: "/transaction-basic",
    //     },
    //     {
    //       text: "Trans List - Crypto",
    //       link: "/transaction-crypto",
    //     },
    //   ],
    // },
    // {
    //   icon: "grid-alt",
    //   text: "Applications",
    //   active: false,
    //   subMenu: [
    //     {
    //       text: "Calendar",
    //       link: "/app-calender",
    //     },
    //     {
    //       text: "Kanban Board",
    //       link: "/app-kanban",
    //     },
    //   ],
    // },
    // {
    //   icon: "file-docs",
    //   text: "Invoice",
    //   active: false,
    //   subMenu: [
    //     {
    //       text: "Invoice List",
    //       link: "/invoice-list",
    //     }
    //   ],
    // },
    // {
    //   icon: "view-col",
    //   text: "Pricing Table",
    //   link: "/pricing-table",
    //   badge: "new"
    // },
  ];
}

else if (userRole == "1"){
  menu = [
    {
      icon: "home-fill",
      text: "Dashboard",
      link: "/",
    },
    // {
    //   heading: "Pre-built Pages",
    // },
    {
      icon: "cpu",
      text: "Manage Devices",
      active: false,
      subMenu: [
        {
          text: "Device List",
          link: "/device-list-regular",
        }
      ],
    },
  ];
}

export default menu; // Now menu is accessible outside of the if statements
