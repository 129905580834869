import React, { useContext, useEffect, useState, useMemo } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Card, Modal, ModalBody, Badge, Spinner } from "reactstrap";
import { Line, Bar, Pie, PolarArea, Doughnut } from "react-chartjs-2";
import { LineChartExample } from "../../../components/Component";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Col,
  Row,
  OverlineTitle,
  Sidebar,
  UserAvatar,
} from "../../../components/Component";
import { useNavigate, useParams } from "react-router-dom";
import {
  currentTime,
  findUpper,
  monthNames,
  todaysDate,
} from "../../../utils/Utils";
import { UserContext } from "./UserContext";
import { notes } from "./UserData";
import DatePicker from "react-datepicker";
import DateTimePicker from "../../components/forms/DateTimePicker";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { BarChart } from "../../../components/partials/charts/default/Charts";
import Logo from "../../../images/VitaWhite.png"

const DeviceDetailsPage = () => {
  const { contextData } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [data] = contextData;
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const [sensorData, setSensorData] = useState([]);
  const [totalData, settotalData] = useState([]);
  const [diffData, setDiffData] = useState([]);
  const [rangeDate, setRangeDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  
  const onRangeChange = (dates) => {
    const [start, end] = dates;
    setRangeDate({ start: start, end: end });
  };

  let { userId } = useParams();

  useEffect(() => {
    const id = userId;
    if (id !== undefined || null || "") {
      fetch(
        `https://api.karthi.ind.in/pages/devices/read.php?unique_device_id=${id}`
      )
        .then((response) => response.json())
        .then((userr) => {
          const user = userr.data;
          // Update the userData state with the fetched user data
          setUser({
            id: id,
            unique_device_id: user.unique_device_id,
            user_unique_id: user.user_unique_id,
            device_name: user.device_name,
            device_model: user.device_model,
            mac: user.mac,
            status: user.status,
            device_key: user.device_key,
            last_active_time: user.last_active_time,
            update_date: user.update_date,
            added_date: user.added_date,
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setLoading(false);
        });
    } else {
      setUser(data[0]);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (rangeDate.start && rangeDate.end && user && user.mac) {
      const formattedStartDate = rangeDate.start.toISOString().substr(0, 10);
      const formattedEndDate = rangeDate.end.toISOString().substr(0, 10);
      // console.log("Updated Start Date:", formattedStartDate);
      // console.log("Updated End Date:", formattedEndDate);

      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://api.karthi.ind.in/pages/devices/read_data.php?mac=${user.mac}&start=${formattedStartDate}&end=${formattedEndDate}`
          );
          if (response.status === 200) {
            setSensorData(
              response.data.data.map((sensData) => ({
                id: sensData.id,
                voltage: sensData.voltage,
                current: sensData.current,
                units: sensData.units,
                added_date: sensData.added_date,
              }))
            );
            settotalData({
              total_units: response.data.total_units,
              max_voltage: response.data.max_voltage,
              min_voltage: response.data.min_voltage,
              max_current: response.data.max_current,
              min_current: response.data.min_current,
            });
            setDiffData({
              total_units_diff:
                response.data.previous_period_comparison.total_units_diff,
              max_voltage_diff:
                response.data.previous_period_comparison.max_voltage_diff,
              min_voltage_diff:
                response.data.previous_period_comparison.min_voltage_diff,
              max_current_diff:
                response.data.previous_period_comparison.max_current_diff,
              min_current_diff:
                response.data.previous_period_comparison.min_current_diff,
            });
          }
        } catch (e) {
          if (e.response) {
            setSensorData([]);
            settotalData([]);
            setDiffData([]);
          }
        }
      };
      fetchData();
    }
  }, [rangeDate, user]);

  // useEffect(() => {
  //   console.log("Updated sensorData:", sensorData);
  //   console.log("Updated totaldata:", totalData);
  //   console.log("Updated diffData:", diffData);
  // }, [sensorData, totalData, diffData]);

  const voltagechartData = useMemo(() => {
    return {
      labels: sensorData.map((sensData) => sensData.added_date),
      datasets: [
        {
          label: "Voltage",
          data: sensorData.map((sensData) => sensData.voltage),
          pointRadius: 0,
          borderColor: "rgba(255, 0, 0, 1)",
          backgroundColor: "rgba(255, 0, 0, 0.05)",
          fill: true,
        },
      ],
    };
  }, [sensorData]);

  const currentchartData = useMemo(() => {
    return {
      labels: sensorData.map((sensData) => sensData.added_date),
      datasets: [
        {
          label: "Current",
          data: sensorData.map((sensData) => sensData.current),
          pointRadius: 0,
          borderColor: "rgba(51, 102, 255, 1)",
          backgroundColor: "rgba(51, 102, 255, 0.05)",
          fill: true,
        },
      ],
    };
  }, [sensorData]);

  const unitstchartData = useMemo(() => {
    return {
      labels: sensorData.map((sensData) => sensData.added_date),
      datasets: [
        {
          label: "Units",
          data: sensorData.map((sensData) => sensData.units),
          pointRadius: 0,
          borderColor: "rgba(255, 51, 153, 1)",
          backgroundColor: "rgba(255, 51, 153, 0.05)",
          fill: true,
        },
      ],
    };
  }, [sensorData]);

  function formatTimeAgo(previousDate) {
    if (!previousDate) return "";

    const current = new Date();
    const previous = new Date(previousDate);
    const seconds = Math.floor((current - previous) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
      return `${interval} years ago`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return `${interval} months ago`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return `${interval} days ago`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return `${interval} hours ago`;
    }
    interval = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    if (interval >= 1) {
      // return `${interval} min${interval > 1 ? 's' : ''} ${remainingSeconds} sec${remainingSeconds !== 1 ? 's' : ''} ago`;
      return `${interval} minutes${interval > 1 ? "s" : ""} ago`;
    }
    return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
  }


  const captureAllPDF = (chartid, downloadid, filename) => {
    if (!sensorData) {
      alert("No data found")
      return;
    }
    const chartContainer = document.getElementById(chartid);
    const elementToExclude = document.getElementById(downloadid);

    if (!elementToExclude) {
      console.error("Element with id 'downloaddata' not found.");
      return;
    }

    html2canvas(chartContainer, {
      ignoreElements: (element) => element === elementToExclude,
    })
      .then((canvas) => {
        const imageData = canvas.toDataURL("image/jpeg", 5.0);

        const imgWidth = 180;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const pdf = new jsPDF({
          unit: "mm",
          format: 'a4',
        });

        // pdf.setFillColor(0, 0, 0); // Black color
        // pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');

        const logo = new Image();
        logo.crossOrigin = "anonymous";
        logo.src = Logo;

        logo.onload = () => {
          const logoWidth = 30;
          const logoHeight = 20;
          const logoX = pdf.internal.pageSize.width - logoWidth - 10;
          const logoY = 2;
          // pdf.addImage(logo, "JPEG", logoX, logoY, logoWidth, logoHeight);

          pdf.setFont("helvetica", "bold");
          pdf.setFontSize(20);
          pdf.setDrawColor(0);
          pdf.setTextColor(255, 0, 0);
          pdf.text(`Usage report ( ${rangeDate.start &&
                                rangeDate.start.toISOString().substr(0, 10)} - ${rangeDate.end &&
                                rangeDate.end.toISOString().substr(0, 10)} )`, 10, 20);

          pdf.addImage(imageData, "JPEG", 10, 30, imgWidth, imgHeight);

          pdf.save(`${filename}.pdf`);
          // alert("Report downloaded successfully")
        };

        logo.onerror = () => {
          console.error("Failed to load logo image");
        };
      })
      .catch((error) => {
        console.error("Error capturing chart:", error);
      });
  };


  return (
    <>
      <Head title="Device Details - Regular"></Head>
      {loading ? (
        <Spinner style={{ display: "block", margin: "auto" }} />
      ) : (
        user && (
          <Content>
            <div id="downloaddata">
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle tag="h3" page>
                    Master Device details of{" "}
                    <span className="text-primary big">
                      {user.device_name}{" "}
                    </span>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      <li>
                        Device name:{" "}
                        <span className="text-base">{user.device_name}</span>
                      </li>
                      <li>
                        Last active:{" "}
                        <span className="text-base">
                          {user.last_active_time &&
                            formatTimeAgo(new Date(user.last_active_time))}
                        </span>
                      </li>
                      <li>
                        Device mode:{" "}
                        <span className="dtr-data">
                          <span
                            className={`tb-status text-${
                              user.last_active_time &&
                              new Date(user.last_active_time) >
                                new Date(Date.now() - 11 * 60 * 1000)
                                ? "success"
                                : "danger"
                            }`}
                          >
                            {user.last_active_time &&
                            new Date(user.last_active_time) >
                              new Date(Date.now() - 11 * 60 * 1000)
                              ? "Online"
                              : "Offline"}
                          </span>
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <Button
                    color="light"
                    outline
                    className="bg-white d-none d-sm-inline-flex"
                    onClick={() => navigate(-1)}
                  >
                    <Icon name="arrow-left"></Icon>
                    <span>Back</span>
                  </Button>

                  <Button color="light" outline className="btn-white" style={{marginLeft: 10}} onClick={() => captureAllPDF("allreports", "downloaddata", "Test-file")}>
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                  </Button>

                  <a
                    href="#back"
                    onClick={(ev) => {
                      ev.preventDefault();
                      navigate(-1);
                    }}
                    className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                  >
                    <Icon name="arrow-left"></Icon>
                  </a>
                      
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            </div>

            <div id="allreports" className="mt-5">
            <Block>
              <Card className="card-bordered">
                <div className="card-aside-wrap" id="user-detail-block">
                  <div className="card-content">
                    <div className="card-inner">
                      <Block>
                        <BlockHead>
                          <BlockTitle tag="h5">Device Information</BlockTitle>
                          {/* <p>Basic info, like your name and address, that you use on Nio Platform.</p> */}
                        </BlockHead>
                        <div className="profile-ud-list">
                          <div className="profile-ud-item">
                            <div className="profile-ud wider">
                              <span className="profile-ud-label">
                                Device Name
                              </span>
                              <span className="profile-ud-value">
                                {user.device_name}
                              </span>
                            </div>
                          </div>
                          <div className="profile-ud-item">
                            <div className="profile-ud wider">
                              <span className="profile-ud-label">
                                Mac Address
                              </span>
                              <span className="profile-ud-value">
                                {user.mac}
                              </span>
                            </div>
                          </div>
                          <div className="profile-ud-item">
                            <div className="profile-ud wider">
                              <span className="profile-ud-label">
                                Device Type
                              </span>
                              <span className="profile-ud-value">
                                {user.device_model}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Block>

                      <Block>
                        <BlockHead className="nk-block-head-line">
                          <BlockTitle
                            tag="h6"
                            className="overline-title text-base"
                          >
                            Additional Information
                          </BlockTitle>
                        </BlockHead>
                        <div className="profile-ud-list">
                          <div className="profile-ud-item">
                            <div className="profile-ud wider">
                              <span className="profile-ud-label">
                                Added Date
                              </span>
                              <span className="profile-ud-value">
                                {user.added_date}
                              </span>
                            </div>
                          </div>
                          <div className="profile-ud-item">
                            <div className="profile-ud wider">
                              <span className="profile-ud-label">
                                Last Updated Date
                              </span>
                              <span className="profile-ud-value">
                                {user.update_date}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Block>

                      {/* <div className="nk-divider divider md"></div> */}
                    </div>
                  </div>
                </div>
              </Card>
              <Card className="card-bordered">
                <div className="card-aside-wrap" id="user-detail-block">
                  <div className="card-content">
                    <div className="card-inner">
                      <Block>
                        <BlockHead>
                          <div className="d-flex justify-content-between align-items-center">
                            <BlockTitle tag="h5">Data Information</BlockTitle>
                            <div className="ml-auto">
                              <DatePicker
                                selected={rangeDate.start}
                                startDate={rangeDate.start}
                                onChange={onRangeChange}
                                endDate={rangeDate.end}
                                selectsRange
                                className="form-control date-picker"
                              />
                              
                            </div>
                          </div>
                          <p>
                            Basic info, like your name and address, that you use
                            on Nio Platform.
                          </p>
                        </BlockHead>
                      </Block>
                    </div>

                    <Row className="d-flex align-items-center justify-content-center">
                      <Col sm="12" lg="4" xxl="4">
                      <PreviewCard className="my-2 mx-4">
                          <h5 className="card-title">
                            Total units consumed [kWh]
                          </h5>
                          <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
                            <div className="nk-sale-data-group flex-md-nowrap g-4">
                              <div className="nk-sale-data">
                                <span className="amount" tag="h3">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <h2
                                      className="card-title"
                                      style={{ margin: 0 }}
                                    >
                                      <span style={{ color: "#798bff" }}>
                                        {totalData.total_units}
                                      </span>
                                    </h2>
                                    <p style={{ margin: 0, marginLeft: "5px" }}>
                                      Units
                                    </p>
                                    <span
                                      className={`change ${
                                        diffData.total_units_diff > 0
                                          ? "down text-success"
                                          : "up text-danger"
                                      }`}
                                    >
                                      <Icon
                                        name={
                                          diffData.total_units_diff < 0
                                            ? "arrow-long-down"
                                            : "arrow-long-up"
                                        }
                                      />
                                      {Math.abs(diffData.total_units_diff)} %
                                    </span>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </PreviewCard>
                      </Col>

                      <Col sm="12" lg="4" xxl="4">
                      <PreviewCard className="my-2 mx-4">
                          <h5 className="card-title">Max Voltage [V]</h5>
                          <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
                            <div className="nk-sale-data-group flex-md-nowrap g-4">
                              <div className="nk-sale-data">
                                <span className="amount" tag="h3">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <h2
                                      className="card-title"
                                      style={{ margin: 0 }}
                                    >
                                      <span style={{ color: "#798bff" }}>
                                        {totalData.max_voltage}
                                      </span>
                                    </h2>
                                    <p style={{ margin: 0, marginLeft: "5px" }}>
                                      Volts
                                    </p>
                                    <span
                                      className={`change ${
                                        diffData.max_voltage_diff > 0
                                          ? "down text-success"
                                          : "up text-danger"
                                      }`}
                                    >
                                      <Icon
                                        name={
                                          diffData.max_voltage_diff < 0
                                            ? "arrow-long-down"
                                            : "arrow-long-up"
                                        }
                                      />
                                      {Math.abs(diffData.max_voltage_diff)} %
                                    </span>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </PreviewCard>
                      </Col>

                      <Col sm="12" lg="4" xxl="4">
                      <PreviewCard className="my-2 mx-4">
                          <h5 className="card-title">Max Current [A]</h5>
                          <div className="nk-sale-data">
                            <span className="amount">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <h2
                                  className="card-title"
                                  style={{ margin: 0 }}
                                >
                                  <span style={{ color: "#798bff" }}>
                                    {totalData.max_current}
                                  </span>
                                </h2>
                                <p style={{ margin: 0, marginLeft: "5px" }}>
                                  Amps
                                </p>
                                <span
                                  className={`change ${
                                    diffData.max_current_diff > 0
                                      ? "down text-success"
                                      : "up text-danger"
                                  }`}
                                >
                                  <Icon
                                    name={
                                      diffData.max_current_diff < 0
                                        ? "arrow-long-down"
                                        : "arrow-long-up"
                                    }
                                  />
                                  {Math.abs(diffData.max_current_diff)} %
                                </span>
                              </div>
                            </span>
                          </div>
                        </PreviewCard>
                      </Col>
                    </Row>

                    {/* --------------------------------Voltage chart-------------------------------------------- */}
                    <div className="d-flex align-items-center justify-content-center">
                      <Col sm="12" lg="12" xxl="12">
                        <PreviewCard className="my-2 mx-4">
                          <h5 className="card-title">
                            Voltage readings [V] ({" "}
                            <span
                              style={{
                                fontWeight: "lighter",
                                color: "#9eaecf",
                                fontSize: 16,
                              }}
                            >
                              {rangeDate.start &&
                                rangeDate.start.toISOString().substr(0, 10)}
                            </span>{" "}
                            -{" "}
                            <span
                              style={{
                                fontWeight: "lighter",
                                color: "#9eaecf",
                                fontSize: 16,
                              }}
                            >
                              {rangeDate.end &&
                                rangeDate.end.toISOString().substr(0, 10)}
                            </span>{" "}
                            )
                          </h5>
                          <div className="nk-ck">
                            <LineChartExample
                              legend={false}
                              data={voltagechartData}
                              grid={false}
                            />
                          </div>
                        </PreviewCard>
                      </Col>
                    </div>
                    {/* --------------------------------Current chart-------------------------------------------- */}
                    <div className="d-flex align-items-center justify-content-center">
                      <Col sm="12" lg="12" xxl="12">
                        <PreviewCard className="my-2 mx-4">
                          <h5 className="card-title">
                            Current readings [A] ({" "}
                            <span
                              style={{
                                fontWeight: "lighter",
                                color: "#9eaecf",
                                fontSize: 16,
                              }}
                            >
                              {rangeDate.start &&
                                rangeDate.start.toISOString().substr(0, 10)}
                            </span>{" "}
                            -{" "}
                            <span
                              style={{
                                fontWeight: "lighter",
                                color: "#9eaecf",
                                fontSize: 16,
                              }}
                            >
                              {rangeDate.end &&
                                rangeDate.end.toISOString().substr(0, 10)}
                            </span>{" "}
                            )
                          </h5>
                          <div className="nk-ck">
                            <LineChartExample
                              legend={false}
                              data={currentchartData}
                              grid={false}
                            />
                          </div>
                        </PreviewCard>
                      </Col>
                    </div>
                    {/* --------------------------------Current chart-------------------------------------------- */}
                    <Row className="d-flex align-items-center justify-content-center">
                      <Col sm="12" lg="12" xxl="12">
                        <PreviewCard className="my-2 mx-4">
                          <h5 className="card-title">
                            Total Units readings [kWh] ({" "}
                            <span
                              style={{
                                fontWeight: "lighter",
                                color: "#9eaecf",
                                fontSize: 16,
                              }}
                            >
                              {rangeDate.start &&
                                rangeDate.start.toISOString().substr(0, 10)}
                            </span>{" "}
                            -{" "}
                            <span
                              style={{
                                fontWeight: "lighter",
                                color: "#9eaecf",
                                fontSize: 16,
                              }}
                            >
                              {rangeDate.end &&
                                rangeDate.end.toISOString().substr(0, 10)}
                            </span>{" "}
                            )
                          </h5>
                          <div className="nk-ck">
                            <LineChartExample
                              legend={false}
                              data={unitstchartData}
                              grid={false}
                            />
                          </div>
                        </PreviewCard>
                      </Col>
                    </Row>
                    <br></br>
                  </div>
                </div>
              </Card>
            </Block>
            </div>
          </Content>
        )
      )}
    </>
  );
};
export default DeviceDetailsPage;
