import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  RSelect,
} from "../../components/Component";
import { Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from 'axios'
import { Toast, ToastHeader, ToastBody, Alert, UncontrolledAlert } from "reactstrap";

const filterStatus = [
  { value: "approved", label: "Approved" },
  { value: "pending", label: "Pending" },
  { value: "2", label: "Deleted" },
  { value: "rejected", label: "Rejected" },]


const Register = () => {
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setisOpen] = useState(false);

  // const toggle = () => {
  //   setisOpen(!isOpen);
  // };

  // const toggleSuc = () => {
  //   setisOpenSuc(!isOpenSuc);
  // };

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [role, setRole] = useState("")

  const navigate = useNavigate();

  const handleFormSubmit = (formData) => {
    console.log(`${formData.name}`);
    console.log(`${formData.email}`);
    console.log(`${formData.phone}`);
    console.log(`${formData.passcode}`);
    console.log(role);

    const apiData = {
      name: formData.name,
      email: formData.email,
      phoneNumber: formData.phone,
      password: formData.passcode,
    };
  
    axios
      .post("https://iot.karthi.ind.in/iot-api/register", apiData)
      .then((response) => {
        console.log(response);
        setLoading(true);
        setTimeout(() => {
          navigate(`${process.env.PUBLIC_URL}/auth-success`);
        }, 1000);
      })
      .catch((error) => {
        console.error("Error:", error);
        setisOpen(true); // Set isOpen to true to show the toast
        
      });
  };
  
  return <>
    <Head title="Register" />
    {/* <Toast isOpen={isOpen} style={{ position: "absolute", top: 5, right: 0 }}>
        <ToastHeader
          close={
            <>
              <small>11 mins ago</small>
              <button className="close" onClick={toggle}>
                <span>&times;</span>
              </button>
            </>
          }
        >
          <strong className="text-primary">Error</strong>
        </ToastHeader>
        <ToastBody>Error occurred during form submission.</ToastBody>
      </Toast> */}
      <UncontrolledAlert isOpen={isOpen} style={{ position: "absolute", top: 10, right: 10, left: 1050}} className="alert-icon" color="danger">
      <Icon name="alert-circle" />
      <strong>Unable to Register</strong>.
      Try again after somethimes.
    </UncontrolledAlert>
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          {/* <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link> */}
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Register</BlockTitle>
              <BlockDes>
                <p>Create New Dashlite Account</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <form className="is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="form-group">
              <label className="form-label" htmlFor="name">
                Name
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="name"
                  {...register('name', { required: true })}
                  placeholder="Enter your name"
                  className="form-control-lg form-control" />
                {errors.name && <p className="invalid">This field is required</p>}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="email">
                  Email or Username
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  type="text"
                  bssize="lg"
                  id="email"
                  {...register('email', { required: true })}
                  className="form-control-lg form-control"
                  placeholder="Enter your email address or username" />
                {errors.email && <p className="invalid">This field is required</p>}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  Phone
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  type="text"
                  bssize="lg"
                  id="default-01"
                  {...register('phone', { required: true })}
                  className="form-control-lg form-control"
                  placeholder="Enter your mobile number" />
                {errors.phone && <p className="invalid">This field is required</p>}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Status</label>
              <div className="form-control-wrap">
                <RSelect
                  options={filterStatus}
                  onChange={(e) => setRole(e.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassState(!passState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={passState ? "text" : "password"}
                  id="password"
                  {...register('passcode', { required: "This field is required" })}
                  placeholder="Enter your passcode"
                  className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`} />
                {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
              </div>
            </div>
            <div className="form-group">
              <Button type="submit" color="primary" size="lg" className="btn-block">
                {loading ? <Spinner size="sm" color="light" /> : "Register"}
              </Button>
            </div>
          </form>
          <div className="form-note-s2 text-center pt-4">
            {" "}
            Already have an account?{" "}
            <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
              <strong>Sign in instead</strong>
            </Link>
          </div>
          {/* <div className="text-center pt-4 pb-3">
            <h6 className="overline-title overline-title-sap">
              <span>OR</span>
            </h6>
          </div>
          <ul className="nav justify-center gx-8">
            <li className="nav-item">
              <a
                className="nav-link"
                href="#socials"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Facebook
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#socials"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Google
              </a>
            </li>
          </ul> */}
        </PreviewCard>
      </Block>
      {/* <AuthFooter /> */}
  </>;
};
export default Register;
