import React, { useState, useEffect } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import LoginBg from "../../images/login-bg.jpg";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  Col,
  Row,
} from "../../components/Component";
import { Form, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log(width);
  console.log(height);

  const globalBg = {
    backgroundImage: `url(${LoginBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "cente",
    padding: "20px",
    display: "flex",
  };

  const myStyle = {
    
  };

  const onFormSubmit = (formData) => {
    const apiData = {
      phone: formData.phone,
      password: formData.passcode,
    };

    setLoading(true);
    const loginName = "8940421646";
    const pass = "12345678";

    axios
      .post("https://api.karthi.ind.in/pages/users/auth-login.php", apiData)
      .then((response) => {
        console.log(response);
        setLoading(true);
        setTimeout(() => {
          localStorage.setItem("accessToken", "token");
          localStorage.setItem("userLogged", true);
          localStorage.setItem("userName", response.data.data.name);
          localStorage.setItem("userEmail", response.data.data.mail);
          localStorage.setItem("userRole", response.data.data.role);
          localStorage.setItem("phone", response.data.data.phone);
          localStorage.setItem("unique_id", response.data.data.unique_id);
          localStorage.setItem("status", response.data.data.status);
          localStorage.setItem("user_key", response.data.data.user_key);
          window.history.pushState(
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
            "auth-login",
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
          );
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.error("Error:", error);
        setTimeout(() => {
          setError("Cannot login with credentials");
          setLoading(false);
        }, 1000);
      });

    // if (formData.name === loginName && formData.passcode === pass) {
    //   localStorage.setItem("accessToken", "token");
    //   localStorage.setItem('userLogged', true)
    //   setTimeout(() => {
    //     window.history.pushState(
    //       `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
    //       "auth-login",
    //       `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
    //     );
    //     window.location.reload();
    //   }, 1000);
    // } else {
    //   setTimeout(() => {
    //     setError("Cannot login with credentials");
    //     setLoading(false);
    //   }, 1000);
    // }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  if (localStorage.getItem("accessToken"))
    return <Navigate to={`${process.env.PUBLIC_URL}/`} />;

  return (
    <div style={globalBg}>
      <div>
        <Head title="Login" />
        <Block className="nk-block-middle wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              {/* <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" /> */}
            </Link>
          </div>

          <Row className="justify-content-start justify-content-md-center" >
            <Col sm="12" md="12" lg="12" xl="12" >
              <PreviewCard
                className="card-bordered border-0 bg-body bg-opacity-10 "
                bodyClass="card-inner-lg"
              >
                <BlockHead>
                  <BlockContent>
                    <BlockTitle tag="h4" className="text-white">
                      Sign-In
                    </BlockTitle>
                    <BlockDes>
                      <p className="text-white">
                        Access your Energy panel with register mobile number &
                        password.
                      </p>
                    </BlockDes>
                  </BlockContent>
                </BlockHead>
                {errorVal && (
                  <div className="mb-3 bg-danger">
                    <Alert
                      color="danger"
                      className="alert-icon bg-danger text-white"
                    >
                      <Icon name="alert-circle" /> Unable to login with
                      credentials{" "}
                    </Alert>
                  </div>
                )}
                <Form
                  className="is-alter"
                  onSubmit={handleSubmit(onFormSubmit)}
                >
                  <div className="form-group text-white">
                    <div className="form-label-group">
                      <label
                        className="form-label text-white"
                        htmlFor="default-01"
                      >
                        Mobile number
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="default-01"
                        {...register("phone", {
                          required: "This field is required",
                        })}
                        // defaultValue="8940421646"
                        placeholder="Enter your registered mobile number"
                        className="form-control-lg form-control"
                      />
                      {errors.phone && (
                        <span className="invalid">{errors.phone.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label
                        className="form-label text-white"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      {/* <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                    Forgot Code?
                  </Link> */}
                    </div>
                    <div className="form-control-wrap">
                      <a
                        href="#password"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPassState(!passState);
                        }}
                        className={`form-icon lg form-icon-right passcode-switch ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                      >
                        <Icon
                          name="eye"
                          className="passcode-icon icon-show"
                        ></Icon>

                        <Icon
                          name="eye-off"
                          className="passcode-icon icon-hide"
                        ></Icon>
                      </a>
                      <input
                        type={passState ? "text" : "password"}
                        id="password"
                        {...register("passcode", {
                          required: "This field is required",
                        })}
                        // defaultValue="8940421646"
                        placeholder="Enter your password"
                        className={`form-control-lg form-control ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                      />
                      {errors.passcode && (
                        <span className="invalid">
                          {errors.passcode.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <Button
                      size="lg"
                      className="btn-block"
                      type="submit"
                      color="primary"
                    >
                      {loading ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        "Sign in"
                      )}
                    </Button>
                  </div>
                </Form>
                {/* <div className="form-note-s2 text-center pt-4">
              New on our platform? <Link to={`${process.env.PUBLIC_URL}/auth-register`}>Create an account</Link>
            </div> */}
                {/* <div className="text-center pt-4 pb-3">
              <h6 className="overline-title overline-title-sap">
                <span>OR</span>
              </h6>
            </div>
            <ul className="nav justify-center gx-4">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#socials"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Facebook
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#socials"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Google
                </a>
              </li>
            </ul> */}
              </PreviewCard>
            </Col>
          </Row>
        </Block>
        {/* <AuthFooter /> */}
      </div>
    </div>
  );
};
export default Login;
