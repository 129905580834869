import React, { useContext, useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Spinner,
  Badge,
  Modal,
  ModalBody,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  RSelect,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { filterRole } from "./UserData";
import { bulkActionOptions, findUpper } from "../../../utils/Utils";
import { Link } from "react-router-dom";
import { UserContext } from "./UserContext";
import EditModal from "./EditModal";
import DeviceAddModal from "./DeviceAddModal";
import axios from "axios";
import jsPDF from "jspdf";
import moment from "moment/moment";
import "jspdf-autotable";
import ResponseModal from "../ResponseModal";

var unique_userid = localStorage.getItem("unique_id");
var user_key = localStorage.getItem("user_key");
var user_role = localStorage.getItem("userRole");

const filterStatus = [
  { value: "", label: "All Status" }, // Add this line
  { value: "1", label: "Active" },
  { value: "0", label: "Suspended" },
];

const DeviceListRegularPage = () => {
  const [deviceData, setDeviceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [data, setData] = useState([]);

  const [filteredData, setFilteredData] = useState([]); // state to hold filtered data
  const [statusFilter, setStatusFilter] = useState(""); // state to hold status filter value
  const [minAmount, setMinAmount] = useState(""); // state to hold min amount filter value
  const [maxAmount, setMaxAmount] = useState(""); // state to hold max amount filter value
  const [lastSeenHours, setLastSeenHours] = useState("");
  const [apiRefresh, setApiRefresh] = useState(false);
  const [deviceCount, setdeviceCount] = useState(0);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);

  const handleRemoveClick = (deviceId) => {
    setSelectedDeviceId(deviceId);
    setConfirmationModal(true);
  };

  const confirmRemoval = () => {
    if (selectedDeviceId) {
      suspendUser(selectedDeviceId);
      setConfirmationModal(false);
    }
  };

  const cancelRemoval = () => {
    setSelectedDeviceId(null);
    setConfirmationModal(false);
  };

  useEffect(() => {
    // Fetch user data from the API and update the deviceData state
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.karthi.ind.in/pages/users/read.php",
          {
            headers: {
              "X-User-Key": user_key,
            },
          }
        );
        const userdatas = response.data.data;
        setUserData(
          userdatas.map((user) => ({
            value: user.unique_id,
            label: user.name,
          }))
        );
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    if (user_role === "6" || user_role === "5") {
      fetchData();
    }
  }, []); // Empty dependency array to ensure it only runs once on mount

  useEffect(() => {
    // Fetch user data from the API and update the deviceData state
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.karthi.ind.in/pages/devices/read.php"
        );
        const deviceDatas = response.data.data;
        setdeviceCount(deviceDatas.length);
        setDeviceData(
          deviceDatas.map((user) => ({
            id: user.id,
            avatarBg: user.avatarBg || "purple", // Set default image URL if not provided
            name: user.device_name || "",
            unique_device_id: user.unique_device_id || "-",
            user_unique_id: user.user_unique_id || "-",
            device_name: user.device_name || "-",
            device_model: user.device_model || "-",
            mac: user.mac || "-",
            status: user.status || "Active",
            last_active_time: user.last_active_time || "-",
            update_date: user.update_date || "-",
            added_date: user.added_date || "-",
            // Set default values for other properties as needed
          }))
        );

        setData(
          deviceDatas.map((user) => ({
            id: user.id,
            avatarBg: user.avatarBg || "purple", // Set default image URL if not provided
            name: user.device_name || "",
            unique_device_id: user.unique_device_id || "-",
            user_unique_id: user.user_unique_id || "-",
            device_name: user.device_name || "-",
            device_model: user.device_model || "-",
            mac: user.mac || "-",
            status: user.status || "Active",
            last_active_time: user.last_active_time || "-",
            update_date: user.update_date || "-",
            added_date: user.added_date || "-",
            // Set default values for other properties as needed
          }))
        );
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userData]); // Empty dependency array to ensure it only runs once on mount

  const { contextData } = useContext(UserContext);

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [respModal, setRespModal] = useState({
    respop: false,
    greetings: "",
    message: "",
    // icon: "ni-cross",
    // iconColor: "danger"
    // icon: "ni-check",
    // iconColor: "success"
    icon: "",
    iconColor: "",
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    device_mac: "",
    device_model: "",
    status: "1",
    user_id: "",
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    email: "",
    balance: 0,
    phone: "",
    status: "",
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = data.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.unique_device_id
            .toLowerCase()
            .includes(onSearchText.toLowerCase()) ||
          item.mac.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setDeviceData([...filteredObject]);
    } else {
      setDeviceData([...data]);
    }
  }, [onSearchText, setData]);

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      device_mac: "",
      device_model: "",
      status: "1",
      user_id: "",
    });
  };

  const closeModal = () => {
    setModal({ add: false });
    resetForm();
  };

  const closeEditModal = () => {
    setModal({ edit: false });
    resetForm();
  };

  const closeRespModal = () => {
    setRespModal({ respop: false });
  };

  // submit function to add a new item
  const onFormSubmit = (submitData) => {
    const { device_mac, device_model, name, status, user_id } = submitData;
    let submittedData = {
      device_name: name,
      mac: device_mac,
      device_model: device_model,
      user_unique_id: user_id,
      status: status,
    };
    const postData = async () => {
      try {
        const response = await axios.post(
          "https://api.karthi.ind.in/pages/devices/create.php",
          submittedData,
          {
            headers: {
              "X-User-Key": user_key,
            },
          }
        );

        if (response.status === 200 && response.data) {
          const apiData = response.data.device_data;
          const mappedData = {
            id: apiData.id,
            avatarBg: apiData.avatarBg || "purple", // Set default image URL if not provided
            name: apiData.device_name || "",
            unique_device_id: apiData.unique_device_id || "-",
            user_unique_id: apiData.user_unique_id || "-",
            device_name: apiData.device_name || "-",
            device_model: apiData.device_model || "-",
            mac: apiData.mac || "-",
            status: apiData.status || "Active",
            last_active_time: apiData.last_active_time || "-",
            update_date: apiData.update_date || "-",
            added_date: apiData.added_date || "-",
          };

          setData((prevData) => [...prevData, mappedData]);
          setDeviceData((prevData) => [...prevData, mappedData]);
          setModal({ edit: false, add: false });
          setRespModal({
            respop: true,
            greetings: "Congratulations",
            message: "Device added successfully",
            icon: "ni-check",
            iconColor: "success",
          });
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
    };
    postData();
    resetForm();
  };

  // submit function to update a new item
  const onEditSubmit = (submitData) => {
    const { name, email, phone } = submitData;
    let submittedData;
    let newitems = data;
    newitems.forEach((item) => {
      if (item.id === editId) {
        submittedData = {
          id: item.id,
          avatarBg: item.avatarBg,
          name: name,
          image: item.image,
          role: item.role,
          email: email,
          balance: editFormData.balance,
          phone: phone,
          emailStatus: item.emailStatus,
          kycStatus: item.kycStatus,
          lastLogin: item.lastLogin,
          status: editFormData.status,
          country: item.country,
        };
      }
    });
    let index = newitems.findIndex((item) => item.id === editId);
    newitems[index] = submittedData;
    setModal({ edit: false });
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setEditFormData({
          name: item.name,
          email: item.email,
          status: item.status,
          phone: item.phone,
          balance: item.balance,
        });
        setModal({ edit: true }, { add: false });
        setEditedId(id);
      }
    });
  };

  // function to change to suspend property for an item
  const suspendUser = (id) => {
    // let newData = data;
    // let index = newData.findIndex((item) => item.id === id);
    // setData([...newData]);
    const deleteData = async () => {
      try {
        const response = await axios.get(
          `https://api.karthi.ind.in/pages/devices/delete.php?unique_device_id=${id}`,
          {
            headers: {
              "X-User-Key": user_key,
            },
          }
        );

        if (response.status === 200) {
          const updatedData = deviceData.filter(
            (item) => item.unique_device_id !== id
          );
          setData(updatedData);
          setDeviceData(updatedData);
          setRespModal({
            respop: true,
            greetings: "Congratulations",
            message: "Device deleted successfully",
            icon: "ni-check",
            iconColor: "success",
          });
        }
      } catch (error) {
        // console.error("Error posting data:", error.response.status);
        if (error.response.status === 403) {
          setRespModal({
            respop: true,
            greetings: "Oops !",
            message: "User not authorized to do this action",
            icon: "ni-cross",
            iconColor: "danger",
          });
        }
      }
    };
    deleteData();
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = deviceData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to handle resetting filters
  const resetFilters = () => {
    setStatusFilter("");
    setDeviceData(data); // Apply filters again after resetting
  };

  const applyFilters = (statusFilter) => {
    if (statusFilter !== "") {
      const filteredData = data.filter((item) => item.status === statusFilter);
      setDeviceData(filteredData);
    } else {
      setDeviceData(data);
    }
  };

  function formatTimeAgo(previousDate) {
    if (!previousDate) return "";

    const current = new Date();
    const previous = new Date(previousDate);
    const seconds = Math.floor((current - previous) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
      return `${interval} years ago`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return `${interval} months ago`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return `${interval} days ago`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return `${interval} hours ago`;
    }
    interval = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    if (interval >= 1) {
      // return `${interval} min${interval > 1 ? 's' : ''} ${remainingSeconds} sec${remainingSeconds !== 1 ? 's' : ''} ago`;
      return `${interval} minutes${interval > 1 ? "s" : ""} ago`;
    }
    return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
  }

  const exportColumns = [
    {
      name: "Device",
      selector: (row) => (row.name === null ? "NA" : row.name),
    },
    {
      name: "Device Type",
      selector: (row) => row.device_model,
    },
    {
      name: "Mac",
      selector: (row) => row.mac,
    },
    {
      name: "Last Updated",
      selector: (row) =>
        row.last_active_time
          ? moment(row.last_active_time).format("M/D/YYYY, h:mm:ss A")
          : "-",
      grow: 2,
    },
  ];

  const exportPDF = () => {
    // console.log("Export data:", deviceData);
    const doc = new jsPDF("portrait");
    doc.text("Device Data", 10, 10);

    const tableData = data.map((row) =>
      exportColumns.map((col) => {
        const selectorResult = col.selector(row);
        if (typeof col.selector === "function") {
          return selectorResult;
        } else {
          return row[col.selector];
        }
      })
    );

    doc.autoTable({
      head: [exportColumns.map((col) => col.name)],
      body: tableData,
    });

    doc.output("dataurlnewwindow", { filename: "device-list" });
    doc.save("device-list");
  };

  return (
    <React.Fragment>
      <Head title="Device List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Devices List
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {deviceCount} devices.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Button
                        color="light"
                        outline
                        className="btn-white"
                        onClick={exportPDF}
                      >
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                    {(user_role === "6" || user_role === "5") && (
                      <li className="nk-block-tools-opt">
                        <Button
                          color="primary"
                          className="btn-icon"
                          onClick={() => setModal({ add: true })}
                        >
                          <Icon name="plus" />
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div className="card-inner position-relative card-tools-toggle">
          <div className="card-title-group">
            <div className="card-tools">
              <div className="form-inline flex-nowrap gx-3"></div>
            </div>
            <div className="card-tools me-n1">
              <ul className="btn-toolbar gx-1">
                <li>
                  <a
                    href="#search"
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle();
                    }}
                    className="btn btn-icon search-toggle toggle-search"
                  >
                    <Icon name="search"></Icon>
                  </a>
                </li>
                <li className="btn-toolbar-sep"></li>
                <li>
                  <div className="toggle-wrap">
                    <Button
                      className={`btn-icon btn-trigger toggle ${
                        tablesm ? "active" : ""
                      }`}
                      onClick={() => updateTableSm(true)}
                    >
                      <Icon name="menu-right"></Icon>
                    </Button>
                    <div
                      className={`toggle-content ${
                        tablesm ? "content-active" : ""
                      }`}
                    >
                      <ul className="btn-toolbar gx-1">
                        <li className="toggle-close">
                          <Button
                            className="btn-icon btn-trigger toggle"
                            onClick={() => updateTableSm(false)}
                          >
                            <Icon name="arrow-left"></Icon>
                          </Button>
                        </li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="btn btn-trigger btn-icon dropdown-toggle"
                            >
                              <div className="dot dot-primary"></div>
                              <Icon name="filter-alt"></Icon>
                            </DropdownToggle>
                            <DropdownMenu
                              end
                              className="filter-wg dropdown-menu-xl"
                              style={{ overflow: "visible" }}
                            >
                              <div className="dropdown-head">
                                <span className="sub-title dropdown-title">
                                  Filter Devices
                                </span>
                              </div>
                              <div className="dropdown-body dropdown-body-rg">
                                <Row className="g-gs">
                                  <Col size="6">
                                    <div className="form-group">
                                      <label className="overline-title overline-title-alt">
                                        Status
                                      </label>
                                      <RSelect
                                        options={filterStatus}
                                        placeholder="All Status"
                                        onChange={(selectedOption) =>
                                          applyFilters(selectedOption.value)
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={`card-search search-wrap ${!onSearch && "active"}`}
            style={{ height: "80px" }}
          >
            <div className="card-body">
              <div className="search-content">
                <Button
                  className="search-back btn-icon toggle-search active"
                  onClick={() => {
                    setSearchText("");
                    toggle();
                  }}
                >
                  <Icon name="arrow-left"></Icon>
                </Button>
                <input
                  type="text"
                  className="border-transparent form-focus-none form-control"
                  placeholder="Search by user or email"
                  value={onSearchText}
                  onChange={(e) => onFilterChange(e)}
                />
                <Button className="search-submit btn-icon">
                  <Icon name="search"></Icon>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Block>
          <DataTable className="card-stretch">
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text text-center">Device</span>
                </DataTableRow>
                <DataTableRow size="mb">
                  <span className="sub-text text-center">Device mode</span>
                </DataTableRow>
                <DataTableRow size="mb">
                  <span className="sub-text text-center">Device Type</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text text-center">Mac</span>
                </DataTableRow>
                {/* <DataTableRow size="lg">
                  <span className="sub-text">Verified</span>
                </DataTableRow> */}
                <DataTableRow size="lg">
                  <span className="sub-text text-center">Last Updated</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text text-center">Status</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools text-end">
                  <UncontrolledDropdown></UncontrolledDropdown>
                </DataTableRow>
              </DataTableHead>

              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.unique_device_id}>
                        <DataTableRow className="text-center">
                          <Link
                            to={`${process.env.PUBLIC_URL}/device-details-regular/${item.unique_device_id}`}
                          >
                            <div className="user-card d-flex-column align-items-center justify-content-center">
                              <div className="user-info">
                                <div>
                                  <span className="tb-lead">{item.name}</span>
                                  <span>{item.unique_device_id}</span>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </DataTableRow>
                        <DataTableRow size="mb" className="text-center">
                          <span className="dtr-data">
                            <Badge
                              pill
                              color={
                                item.last_active_time &&
                                new Date(item.last_active_time) >
                                  new Date(Date.now() - 11 * 60 * 1000)
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {item.last_active_time &&
                              new Date(item.last_active_time) >
                                new Date(Date.now() - 11 * 60 * 1000)
                                ? "Online"
                                : "Offline"}
                            </Badge>
                          </span>
                        </DataTableRow>
                        <DataTableRow size="mb" className="text-center">
                          <span className="">{item.device_model}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className="text-center">
                          <span>{item.mac}</span>
                        </DataTableRow>

                        <DataTableRow size="lg" className="text-center">
                          <span>
                            {item.last_active_time &&
                              formatTimeAgo(new Date(item.last_active_time))}
                          </span>
                        </DataTableRow>

                        <DataTableRow size="md" className="text-center">
                          <span
                            className={`tb-status text-${
                              item.status === "1"
                                ? "success"
                                : item.status === "0"
                                ? "danger"
                                : "warning"
                            }`}
                          >
                            {item.status === "1" ? "Active" : "Inactive"}
                          </span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-center">
                          <ul className="nk-tb-actions gx-1">
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onEditClick(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>
                                    {item.status !== "1" && (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li
                                          onClick={() =>
                                            handleRemoveClick(
                                              item.unique_device_id
                                            )
                                          }
                                        >
                                          <DropdownItem
                                            tag="a"
                                            href="#suspend"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="trash-fill"></Icon>
                                            <span>Remove device</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    )}
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            {/* <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={deviceData.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div> */}
            <div className="card-inner">
              {loading ? (
                // Show Spinner component when loading is true
                <Spinner style={{ display: "block", margin: "auto" }} />
              ) : // Show PaginationComponent if there are items
              currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={deviceData.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                // If there are no items, show "No data found" message
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>

        <DeviceAddModal
          modal={modal.add}
          formData={formData}
          setFormData={setFormData}
          closeModal={closeModal}
          onSubmit={onFormSubmit}
          filterStatus={filterStatus}
          userData={userData}
        />
        <EditModal
          modal={modal.edit}
          formData={editFormData}
          setFormData={setEditFormData}
          closeModal={closeEditModal}
          onSubmit={onEditSubmit}
          filterStatus={filterStatus}
        />
        <ResponseModal
          modal={respModal.respop}
          closeModal={closeRespModal}
          greetings={respModal.greetings}
          message={respModal.message}
          icon={respModal.icon}
          iconColor={respModal.iconColor}
        />
        <Modal
          isOpen={confirmationModal}
          toggle={cancelRemoval}
          className="modal-dialog-centered"
        >
          <ModalBody>
            <div className="p-2">
              <h5 className="title">Confirmation</h5>
              <p>Are you sure you want to remove this device?</p>
              <div className="d-flex justify-content-end">
                <Button color="danger" onClick={confirmRemoval}>
                  Yes
                </Button>
                <Button
                  color="secondary"
                  className="ms-2"
                  onClick={cancelRemoval}
                >
                  No
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default DeviceListRegularPage;
