import React from "react";
import {
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap";
import { Icon } from "../../../Component";
import { LineChart } from "../../charts/default/Charts";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DatePicker from "react-datepicker";

const SalesOverview = () => {
  const navigate = useNavigate();

  const [rangeDate, setRangeDate] = useState({
    start: new Date(),
    end: new Date(),
  });

  const onRangeChange = (dates) => {
    const [start, end] = dates;
    setRangeDate({ start: start, end: end });
  };

  return (
    <React.Fragment>
      <div className="card-title-group align-start gx-3 mb-3">
        <div className="card-title">
          <h6 className="title">Consumption Overview</h6>
          <p>
            Consumption of all devices from{" "}
            {rangeDate.start && rangeDate.start.toISOString().substr(0, 10)} to{" "}
            {rangeDate.end && rangeDate.end.toISOString().substr(0, 10)}.{" "}
            <a
              href="#details"
              onClick={(ev) => {
                ev.preventDefault();
                navigate("/device-list-regular");
              }}
            >
              See based on devices
            </a>
          </p>
        </div>
        <div className="ml-auto">
          <DatePicker
            selected={rangeDate.start}
            startDate={rangeDate.start}
            onChange={onRangeChange}
            endDate={rangeDate.end}
            selectsRange
            className="form-control date-picker"
          />
        </div>
        {/* <div className="card-tools">
          <UncontrolledDropdown>
            <DropdownToggle tag="div" color="transparent">
              <a
                href="#toggle"
                onClick={(ev) => ev.preventDefault()}
                className="btn btn-primary btn-dim d-none d-sm-inline-flex"
              >
                <Icon className="d-none d-sm-inline" name="download-cloud" />
                <span>
                  <span className="d-none d-md-inline">Download</span> Report
                </span>
              </a>
              <a
                href="#toggle"
                onClick={(ev) => ev.preventDefault()}
                className="btn btn-primary btn-icon btn-dim d-sm-none"
              >
                <Icon name="download-cloud" />
              </a>
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropdownitem"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <span>Download Mini Version</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropdownitem"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <span>Download Full Version</span>
                  </DropdownItem>
                </li>
                <li className="divider"></li>
                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropdownitem"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    <Icon name="opt-alt" />
                    <span>Options</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div> */}
      </div>
      <div className="nk-sale-data-group align-center justify-between gy-3 gx-5">
        <div className="nk-sale-data">
          <span className="amount">340 Units</span>
        </div>
        {/* <div className="nk-sale-data">
          <span className="amount sm">
            <small>Units Consumed</small>
          </span>
        </div> */}
      </div>
      <div className="nk-sales-ck large pt-4">
        <LineChart />
      </div>
    </React.Fragment>
  );
};
export default SalesOverview;
