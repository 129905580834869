import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Card, Modal, ModalBody, Badge, Spinner } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  OverlineTitle,
  Sidebar,
  UserAvatar,
} from "../../../components/Component";
import { useNavigate, useParams } from "react-router-dom";
import { currentTime, findUpper, monthNames, todaysDate } from "../../../utils/Utils";
import { UserContext } from "./UserContext";
import { notes } from "./UserData";
import axios from "axios";

const UserDetailsPage = () => {
  const { contextData } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [data] = contextData;
  const [sideBar, setSidebar] = useState(false);
  const [user, setUser] = useState();
  const [noteData, setNoteData] = useState(notes);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [addNoteText, setAddNoteText] = useState("");
  const navigate = useNavigate();

  let { userId } = useParams();

  var unique_userid = localStorage.getItem('unique_id')
  var user_key = localStorage.getItem('user_key')

  useEffect(() => {
    const id = userId;

    const fetchData = async() => {
      try{
        const response = await axios.get(`https://api.karthi.ind.in/pages/users/read.php?unique_id=${id}`,
          {
            headers:{
              'X-User-Key': user_key
            }
          }
        )
        const user = response.data.data
        console.log(user)
        setUser({
          id: user.id,
          name: user.name || "",
          user_unique_id : user.unique_id || "-",
          mail : user.mail || "-",
          phone : user.phone || "-",
          role : user.role || "-",
          address : user.address || "-",
          status: user.status || "Active",
          license_date : user.license_date || "-",
          update_date : user.update_date || "-",
          added_date : user.created_date || "-",  
        });
        setLoading(false);
      }
      catch (e){
        console.log(e)
        setLoading(false);
      }
    }
    
    if (id !== undefined || null || "") {
      fetchData()
    } else {
      setUser(data[0]);
      setLoading(false);
    }
  }, [data]);

  // function to toggle sidebar
  const toggle = () => {
    setSidebar(!sideBar);
  };

  useEffect(() => {
    sideBar ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
  }, [sideBar])

  // delete a note
  const deleteNote = (id) => {
    let defaultNote = noteData;
    defaultNote = defaultNote.filter((item) => item.id !== id);
    setNoteData(defaultNote);
  };

  const submitNote = () => {
    let submitData = {
      id: Math.random(),
      text: addNoteText,
      date: `${monthNames[todaysDate.getMonth()]} ${todaysDate.getDate()}, ${todaysDate.getFullYear()}`,
      time: `${currentTime()}`,
      company: "Softnio",
    };
    setNoteData([...noteData, submitData]);
    setAddNoteModal(false);
    setAddNoteText("");
  };

  return (
    <>
      <Head title="User Details"></Head>
      {loading ? <Spinner style={{ display: 'block', margin: 'auto' }}/> :
      (user && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                Master User details of <span className="text-primary big">{user.name}</span>
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  onClick={() => navigate(-1)}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(-1);
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        href="#personal"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="user-circle"></Icon>
                        <span>Personal</span>
                      </a>
                    </li>
                    <li className="nav-item nav-item-trigger d-xxl-none">
                      <Button className={`toggle btn-icon btn-trigger ${sideBar && "active"}`} onClick={toggle}>
                        <Icon name="user-list-fill"></Icon>
                      </Button>
                    </li>
                  </ul>

                  <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Personal Information</BlockTitle>
                        {/* <p>Basic info, like your name and address, that you use on Nio Platform.</p> */}
                      </BlockHead>
                      <div className="profile-ud-list">
                        
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Full Name</span>
                            <span className="profile-ud-value">{user.name}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Unique User id</span>
                            <span className="profile-ud-value">{user.user_unique_id}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Mobile Number</span>
                            <span className="profile-ud-value">{user.phone}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Email Address</span>
                            <span className="profile-ud-value">{user.mail}</span>
                          </div>
                        </div>
                      </div>
                    </Block>

                    <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                          Additional Information
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Created date</span>
                            <span className="profile-ud-value">{user.added_date}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">License date</span>
                            <span className="profile-ud-value">{user.license_date}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Address</span>
                            <span className="profile-ud-value">{user.address}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">User Status</span>
                            <span className={`profile-ud-value text-${
                            user.status === "1" ? "success" : user.status === "0" ? "danger" : "warning"
                          }`}>{user.status === "1" ? "Active" : "Inactive"}</span>
                          </div>
                        </div>
                      </div>
                    </Block>

                    <div className="nk-divider divider md"></div>
                  </div>
                </div>

                <Sidebar toggleState={sideBar}>
                  <div className="card-inner">
                    <div className="user-card user-card-s2 mt-5 mt-xxl-0">
                      <UserAvatar className="lg" theme="primary" text={findUpper(user.name)} />
                      <div className="user-info">
                        <Badge color="outline-light" pill className="ucap">
                          {user.role === "1" ? "User" : user.role === "6" ? "Admin" : "Unknown"}</Badge>
                        <h5>{user.name}</h5>
                        <span className="sub-text">{user.mail}</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <Row className="text-center">
                      
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{user.status}</span>
                          <span className="sub-text">Online devices</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{user.status}</span>
                          <span className="sub-text">Active devices</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{user.status}</span>
                          <span className="sub-text">Inactive devices</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">Additional</h6>
                    <Row className="g-3">
                      <Col size="6">
                        <span className="sub-text">User Status:</span>
                        <span
                          className={`lead-text text-${
                            user.status === "1" ? "success" : user.status === "0" ? "danger" : "warning"
                          }`}
                        >
                          {user.status === "1" ? "Active" : "Inactive"}
                        </span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">Register At:</span>
                        <span>{new Date(user.added_date).toISOString().split('T')[0]}</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <OverlineTitle tag="h6" className="mb-3">
                    </OverlineTitle>
                  </div>
                </Sidebar>
                {sideBar && <div className="toggle-overlay" onClick={() => toggle()}></div>}
              </div>
            </Card>
          </Block>
        </Content>
      ))}
    </>
  );
};
export default UserDetailsPage;
