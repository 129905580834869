import Router from "./route/Index";
import { Routes, Route } from 'react-router-dom'
import Login from "./pages/auth/Login";
import ThemeProvider from "./layout/provider/Theme";

const App = () => {
  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
};
export default App;