import React, { useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Icon from "../../components/icon/Icon";
import classnames from "classnames";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, BlockDes, BackTo } from "../../components/block/Block";
import { PreviewCard, CodeBlock } from "../../components/preview/Preview";

const ResponseModal = ({ modal, closeModal, greetings, message, icon, iconColor }) => {
  return (
    <Modal isOpen={modal} toggle={() => closeModal()}>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <Icon className={`nk-modal-icon icon-circle icon-circle-xxl ni ${icon} bg-${iconColor}`}></Icon>
          <h4 className="nk-modal-title">{greetings}</h4>
          <div className="nk-modal-text">
            <div className="caption-text">
                {message}
              {/* You’ve successfully bought <strong>0.5968</strong> BTC for{" "} */}
              {/* <strong>200.00</strong> USD */}
            </div>
            {/* <span className="sub-text-sm">
              Learn when you reciveve bitcoin in your wallet.{" "}
              <a href="#link" onClick={(ev) => ev.preventDefault()}>
                {" "}
                Click here
              </a>
            </span> */}
          </div>
          <div className="nk-modal-action">
            <Button
              color="primary"
              size="lg"
              className="btn-mw"
              onClick={() => closeModal()}
            >
              OK
            </Button>
          </div>
        </div>
      </ModalBody>
      {/* <ModalFooter className="bg-light">
        <div className="text-center w-100">
          <p>
            Earn upto $25 for each friend your refer!{" "}
            <a href="#invite" onClick={(ev) => ev.preventDefault()}>
              Invite friends
            </a>
          </p>
        </div>
      </ModalFooter> */}
    </Modal>
  );
};

export default ResponseModal;
