import { Outlet, Navigate } from 'react-router-dom'
var isLogged = localStorage.getItem('userLogged')

function PrivateRoutes () {

    return(
        isLogged ? <Outlet /> : <Navigate to="/auth-login" />
    )
}

export default PrivateRoutes